<template>
  <v-card
    dark
    width="300px"
  >
    <h2>TAKT.ing</h2>
    <p>Admin Dashboard</p>
    <div class="login-line" />
    <div class="login-btn-container">
      <div class="fifty">
        <v-btn
          light
          block
          :href="getHref('Home')"
          @click.left.prevent="navigateToRoute('Home')"
        >
        <!-- :href="this.$router.resolve({
          name: 'Home',
        }).href" -->
          Cancel
        </v-btn>
      </div>
      <div class="fifty">
        <LoginBtn
          :is_logged_in="isLoggedIn"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginBtn from '@/components/LoginBtn.component.vue';
import { Navigation } from '@/mixins/Navigation.mixin.js';

export default {
  components: {
    LoginBtn,
  },

  mixins: [Navigation, ],

  computed: {
      ...mapGetters('Auth', ['isLoggedIn', ]),
  },

  created() {
  if(this.isLoggedIn) {
      this.navigateToRoute('ArticlesDashboard');
    }
  },
}
</script>

<style lang="css" scoped>
.v-btn {
  letter-spacing: normal;
  text-transform: none;
}
.v-card {
  margin: auto;
  text-align: center;
}
.login-btn-container {
  display: flex;
  justify-content: flex-start;
  padding: 0 15px;
}
.login-line {
  margin: 10px 10%;
  padding-top: 10px;
  border-style: solid;
  border-color: white;
  border-width: 0 0 1px 0;
}
.fifty {
  width: 50%;
  margin: 15px;
}
h2 {
  font-weight: lighter;
  font-size: 36pt;
}
p {
  font-weight: 200;
}
</style>
