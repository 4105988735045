<template>
  <v-btn
    dark
    block
    color="blue darken-2"
    v-if="!is_logged_in"
    :href="login_url"
  >
    Login
  </v-btn>
</template>

<script>
export default {
  props: [
    'is_logged_in',
  ],

  data: () => ({
      login_url:  process.env.VUE_APP_LOGIN_URL,
  }),

  created() {
    console.log(this.is_logged_in);
  },
}
</script>

<style lang="css" scoped>

</style>
